import React, { useState, useEffect, useCallback } from 'react';
import IntlCurrencyInput from 'react-intl-currency-input';
import {
  Card,
  Button,
  CardBody,
  Row,
  Col,
  Label,
  FormGroup,
  Collapse,
} from 'reactstrap';
import { formatPrice } from '~/util/format';

import addProductSchema from '~/validators/form/add_product';

import Input from '~/components/Inputs/Input';

const styles = {
  addProductButton: {
    height: '37px',
    minWidth: 'auto',
    padding: '8px',
    display: 'flex',
    backgroundColor: '#EEF5FC',
    border: '1.5px solid #2544B1',
    borderRadius: '4px',
    fontWeight: 450,
    textAlign: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
    float: 'right',
  },
  carrinho: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
  produtoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    backgroundColor: '#f1f1f1',
    marginBottom: '10px',
    userSelect: 'none',
  },
  produtoForm: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '10px',
    marginTop: '10px',
  },
  produtoDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '10px',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#333',
  },
  value: {
    fontSize: '14px',
    color: '#333',
  },
  input: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px',
  },
  inputPrecoUnitario: {
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px',
    width: '100%',
  },
  button: {
    gridColumn: 'span 5',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    textAlign: 'center',
    right: 0,
  },
  deleteButton: {
    backgroundColor: '#FFF1F0',
    border: 'none',
    color: '#E01507',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  editButton: {
    backgroundColor: '#DBEDFF',
    border: 'none',
    color: '#2F80ED',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  cardProduct: {
    border: '1px solid #D9D9D9',
    borderRadius: '0.25rem',
    marginBottom: '10px',
    padding: '6px',
    boxShadow: '0px 0px 0px #ff33ff',
  },
  produtoHeaderRow: {
    display: 'flex',
    alignItems: 'center',
  },
  colButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputEdit: {
    padding: '6px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '12px',
    width: '100%',
  },
  produtoEditRow: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  totalContainer: {
    marginTop: '10px',
  },
  separatorLine: {
    borderTop: '0.5px solid #BFBFBF',
    margin: '10px 0',
  },
  totalLabel: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

const CartProducts = ({ setProducts, setProductsTotal }) => {
  const [products, setLocalProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    id: Date.now(),
    code: Date.now(),
    name: '',
    size: '',
    color: '',
    value: 0,
    prod_amount: 1,
  });
  const [editIndex, setEditIndex] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const calculateTotal = useCallback(() => {
    return products.reduce(
      (total, product) => total + product.value * product.prod_amount,
      0
    );
  }, [products]);

  useEffect(() => {
    const updateTotal = () => {
      const total = calculateTotal();
      setProductsTotal(total || 0);
    };

    updateTotal();
  }, [products, calculateTotal, setProductsTotal]);

  const [validationErrors, setValidationErrors] = useState({});

  const handleAdicionarProduto = async () => {
    const validateProduct = async product => {
      try {
        await addProductSchema.validate(product, { abortEarly: false });
        return { isValid: true, errors: {} };
      } catch (err) {
        const errorsFromValidation = {};
        if (err.inner) {
          err.inner.forEach(error => {
            errorsFromValidation[error.path] = error.message;
          });
        }
        return { isValid: false, errors: errorsFromValidation };
      }
    };

    const { isValid, errors } = await validateProduct(newProduct);

    if (!isValid) {
      setValidationErrors(errors);
      return;
    }

    const updatedProducts = [...products];
    if (editIndex !== null) {
      updatedProducts[editIndex] = newProduct;
      setLocalProducts(updatedProducts);
      setProducts(updatedProducts);
      setEditIndex(null);
    } else {
      setLocalProducts([
        ...products,
        { ...newProduct, id: Date.now(), code: Date.now() },
      ]);
      setProducts([
        ...products,
        { ...newProduct, id: Date.now(), code: Date.now() },
      ]);
    }

    setValidationErrors({});
    setNewProduct({
      id: Date.now(),
      code: Date.now(),
      name: '',
      size: '',
      color: '',
      value: 0,
      prod_amount: 1,
    });
    setShowForm(false);
    setExpandedIndex(null);
  };

  const handleExcluirProduto = id => {
    const updatedProducts = products.filter(produto => produto.id !== id);
    setLocalProducts(updatedProducts);
    setProducts(updatedProducts);
  };

  const handleEditarProduto = index => {
    setNewProduct(products[index]);
    setEditIndex(index);
    setShowForm(false);
    setExpandedIndex(index);
  };

  const toggleExpand = index => {
    if (editIndex === index) return;
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const getAlignAddProductStyle = () => ({
    display: 'flex',
    justifyContent: products.length > 0 ? 'flex-end' : 'flex-start',
  });

  const resetProductForm = () => {
    setNewProduct({
      name: '',
      value: '',
      size: '',
      color: '',
      prod_amount: 1,
    });
  };

  const renderProductForm = () => (
    <Row className="w-100 mx-0">
      <Col lg={4} xs={12} className="px-0 pr-lg-2 mt-2 mt-lg-0">
        <FormGroup>
          <Label>Produto</Label>
          <Input
            type="text"
            name="nameCode"
            placeholder="Nome ou código"
            value={newProduct.name}
            onChange={e =>
              setNewProduct({ ...newProduct, name: e.target.value })
            }
            style={styles.input}
          />
          {validationErrors.name && (
            <span className="text-danger">{validationErrors.name}</span>
          )}
        </FormGroup>
      </Col>
      <Col lg={2} xs={12} className="px-0 pr-lg-2 mt-2 mt-lg-0">
        <FormGroup>
          <Label>Tamanho</Label>
          <Input
            type="text"
            name="size"
            placeholder="Tamanho"
            value={newProduct.size}
            onChange={e =>
              setNewProduct({ ...newProduct, size: e.target.value })
            }
            style={styles.input}
          />
        </FormGroup>
      </Col>
      <Col lg={2} xs={12} className="px-0 pr-lg-2 mt-2 mt-lg-0">
        <FormGroup>
          <Label>Cor</Label>
          <Input
            type="text"
            name="color"
            placeholder="Cor"
            value={newProduct.color}
            onChange={e =>
              setNewProduct({ ...newProduct, color: e.target.value })
            }
            style={styles.input}
          />
        </FormGroup>
      </Col>
      <Col lg={2} xs={12} className="px-0 pr-lg-2 mt-2 mt-lg-0">
        <FormGroup>
          <Label>Quantidade</Label>
          <Input
            type="number"
            name="amount"
            placeholder="Quantidade"
            value={Number(newProduct.prod_amount) || 1}
            onChange={e =>
              setNewProduct({
                ...newProduct,
                prod_amount: parseInt(e.target.value, 10),
              })
            }
            style={styles.input}
          />
          {validationErrors.prod_amount && (
            <span className="text-danger">{validationErrors.prod_amount}</span>
          )}
        </FormGroup>
      </Col>
      <Col lg={2} xs={12} className="px-0 pr-lg-0 mt-2 mt-lg-0">
        <FormGroup>
          <Label>Preço unitário</Label>
          <IntlCurrencyInput
            currency="BRL"
            name="precoUnitario"
            placeholder="Preço unitário"
            onChange={(event, floatValue) => {
              setNewProduct({
                ...newProduct,
                value: parseFloat(floatValue),
              });
            }}
            style={styles.inputPrecoUnitario}
            value={parseFloat(newProduct.value)}
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                },
              },
            }}
          />
          {validationErrors.value && (
            <span className="text-danger">{validationErrors.value}</span>
          )}
        </FormGroup>
      </Col>
    </Row>
  );

  return (
    <Card className="card new-layout mb-0">
      <CardBody className="card-body new-layout px-5">
        <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-2">
          <h2 className="font-size-20">Produtos</h2>
        </Row>
        {products.length === 0 && !showForm ? (
          <Row className="w-100 mx-0 mb-0">
            Ainda não há informações. Clique no botão abaixo para adicionar um
            produto.
          </Row>
        ) : (
          <div style={styles.produtosLista}>
            {products.map((product, index) => (
              <Card
                className="card new-layout list-card alt"
                style={styles.cardProduct}
                id={`collapse-btn-${product.id}`}
                key={product.id || index}
                role="button"
                onClick={() => toggleExpand(index)}
              >
                <CardBody className="card-body new-layout px-2 py-2">
                  <Row className="w-100 mx-0 align-items-center">
                    <Col
                      xs={10}
                      className="font-size-13 font-weight-600 text-gray-700 d-flex align-items-center"
                    >
                      {index + 1}. {product.name}
                    </Col>
                    <Col
                      xs={2}
                      align="end"
                      className="d-flex align-items-center justify-content-end"
                    >
                      <i
                        className={`las la-${
                          expandedIndex === index ? 'minus' : 'plus'
                        } text-secondary font-size-16`}
                        id={`expand-${product.id}`}
                      />
                    </Col>
                  </Row>
                  <Collapse
                    isOpen={expandedIndex === index || editIndex === index}
                    toggler={`collapse-btn-${product.id}`}
                  >
                    <Row className="w-100 mx-0 my-4">
                      <Col className="card-value">
                        <Row className="w-100 mx-0 products-table-row header py-2 d-none d-lg-flex">
                          <Col xs={3} className="font-size-12">
                            Nome ou código
                          </Col>
                          <Col xs={2} className="font-size-12">
                            Tamanho
                          </Col>
                          <Col xs={2} className="font-size-12">
                            Cor
                          </Col>
                          <Col xs={1} className="font-size-12">
                            Qtd
                          </Col>
                          <Col xs={2} className="font-size-12">
                            Preço Unitário
                          </Col>
                          <Col xs={2} className="font-size-12">
                            Valor Total
                          </Col>
                        </Row>
                        <div key={product.id}>
                          {editIndex === index ? (
                            <Row className="w-100 mx-0 products-table-row py-2 d-none d-lg-flex">
                              <Col xs={3}>
                                <input
                                  type="text"
                                  value={newProduct.name}
                                  onChange={e =>
                                    setNewProduct({
                                      ...newProduct,
                                      name: e.target.value,
                                    })
                                  }
                                  style={styles.inputEdit}
                                />
                              </Col>
                              <Col xs={2}>
                                <input
                                  type="text"
                                  value={newProduct.size}
                                  onChange={e =>
                                    setNewProduct({
                                      ...newProduct,
                                      size: e.target.value,
                                    })
                                  }
                                  style={styles.inputEdit}
                                />
                              </Col>
                              <Col xs={2}>
                                <input
                                  type="text"
                                  value={newProduct.color}
                                  onChange={e =>
                                    setNewProduct({
                                      ...newProduct,
                                      color: e.target.value,
                                    })
                                  }
                                  style={styles.inputEdit}
                                />
                              </Col>
                              <Col xs={1}>
                                <input
                                  type="number"
                                  value={newProduct.prod_amount}
                                  onChange={e =>
                                    setNewProduct({
                                      ...newProduct,
                                      prod_amount: parseInt(e.target.value, 10),
                                    })
                                  }
                                  style={styles.inputEdit}
                                />
                              </Col>
                              <Col xs={2}>
                                <IntlCurrencyInput
                                  currency="BRL"
                                  name="precoUnitario"
                                  placeholder="Preço unitário"
                                  onChange={(event, floatValue) => {
                                    setNewProduct({
                                      ...newProduct,
                                      value: parseFloat(floatValue),
                                    });
                                  }}
                                  style={styles.inputEdit}
                                  value={parseFloat(newProduct.value)}
                                  config={{
                                    locale: 'pt-BR',
                                    formats: {
                                      number: {
                                        BRL: {
                                          style: 'currency',
                                          currency: 'BRL',
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        },
                                      },
                                    },
                                  }}
                                />
                              </Col>
                              <Col xs={2}>
                                <span>
                                  {formatPrice(
                                    (
                                      newProduct.value * newProduct.prod_amount
                                    ).toFixed(2)
                                  )}
                                </span>
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <Row className="w-100 mx-0 products-table-row py-2 d-none d-lg-flex">
                                <Col
                                  xs={3}
                                  className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                                >
                                  {product.name}
                                </Col>
                                <Col
                                  xs={2}
                                  className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                                >
                                  {product.size || 'Não informado!'}
                                </Col>
                                <Col
                                  xs={2}
                                  className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                                >
                                  {product.color || 'Não informado!'}
                                </Col>
                                <Col
                                  xs={1}
                                  className="font-size-14 font-weight-400 text-gray-700"
                                >
                                  {`${product.prod_amount}`.padStart(2, 0)}
                                </Col>
                                <Col
                                  xs={2}
                                  className="font-size-14 font-weight-400 text-gray-700"
                                >
                                  {formatPrice(product.value.toFixed(2))}
                                </Col>
                                <Col
                                  xs={2}
                                  className="font-size-14 font-weight-400 text-gray-700"
                                >
                                  {formatPrice(
                                    (
                                      product.value * product.prod_amount
                                    ).toFixed(2)
                                  )}
                                </Col>
                              </Row>
                              {/*  mobile version  */}
                              <Row className="w-100 mx-0 d-lg-none mb-2">
                                <Col className="px-0">
                                  <Row className="w-100 mx-0">
                                    <Col className="px-0">
                                      <div className="d-flex flex-row align-items-baseline">
                                        <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                          Nome:
                                        </div>
                                        <div className="new-layout-card-mobile value d-inline text-truncate">
                                          {product.name}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="w-100 mx-0">
                                    <Col className="px-0">
                                      <div className="d-flex flex-row align-items-baseline">
                                        <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                          Tamanho:
                                        </div>
                                        <div className="new-layout-card-mobile value d-inline text-truncate">
                                          {product.size}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="w-100 mx-0">
                                    <Col className="px-0">
                                      <div className="d-flex flex-row align-items-baseline">
                                        <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                          Cor:
                                        </div>
                                        <div className="new-layout-card-mobile value d-inline text-truncate">
                                          {product.color}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="w-100 mx-0">
                                    <Col className="px-0">
                                      <div className="d-flex flex-row align-items-baseline">
                                        <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                          Quantidade:
                                        </div>
                                        <div className="new-layout-card-mobile value d-inline text-truncate">
                                          {`${product.prod_amount}`.padStart(
                                            2,
                                            0
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="w-100 mx-0">
                                    <Col className="px-0">
                                      <div className="d-flex flex-row align-items-baseline">
                                        <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                          Preço Unitário:
                                        </div>
                                        <div className="new-layout-card-mobile value d-inline text-truncate">
                                          {formatPrice(
                                            product.value.toFixed(2)
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="w-100 mx-0">
                                    <Col className="px-0">
                                      <div className="d-flex flex-row align-items-baseline">
                                        <div className="new-layout-card-mobile label font-weight-500 d-inline">
                                          Valor Total:
                                        </div>
                                        <div className="new-layout-card-mobile value d-inline text-truncate">
                                          {formatPrice(
                                            (
                                              product.value *
                                              product.prod_amount
                                            ).toFixed(2)
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          )}
                          <Row className="w-100 mx-0 mt-4">
                            <Col
                              align="end"
                              className="px-0"
                              style={styles.colButtons}
                            >
                              <Button
                                color="danger"
                                style={styles.deleteButton}
                                onClick={() => handleExcluirProduto(product.id)}
                                className="new-layout-btn main mb-2 mb-lg-0 font-size-14"
                              >
                                <i className="las la-trash pl-1 mr-2 font-size-16" />
                                Excluir
                              </Button>
                              {editIndex === index ? (
                                <Button
                                  color="primary"
                                  style={styles.editButton}
                                  className="new-layout-btn main mb-2 mb-lg-0 font-size-14"
                                  onClick={handleAdicionarProduto}
                                >
                                  Salvar
                                </Button>
                              ) : (
                                <Button
                                  color="secondary"
                                  style={styles.editButton}
                                  className="new-layout-btn main mb-2 mb-lg-0 font-size-14"
                                  onClick={() => handleEditarProduto(index)}
                                  outline
                                >
                                  <i className="las la-pen pl-1 mr-2 font-size-16" />
                                  Editar
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Collapse>
                </CardBody>
              </Card>
            ))}
          </div>
        )}
        {!showForm ? (
          <>
            <Row className="w-100 mx-0 mt-2">
              <Col
                align="end"
                className="px-0"
                style={getAlignAddProductStyle()}
              >
                <Button
                  onClick={() => {
                    resetProductForm();
                    setEditIndex(null);
                    setShowForm(true);
                  }}
                  style={styles.addProductButton}
                >
                  <span style={{ color: '#2544B1' }}>+ Adicionar produto</span>
                </Button>
              </Col>
            </Row>
            {products.length > 0 && (
              <div style={styles.totalContainer}>
                <div style={styles.separatorLine}> </div>
                <div className="float-right">
                  <span className="font-size-14">
                    Valor total dos produtos:
                  </span>{' '}
                  <span className="font-size-14 font-weight-600">
                    {formatPrice(calculateTotal())}
                  </span>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <Row className="w-100 mx-0">{renderProductForm()}</Row>
            <Row className="w-100 mx-0">
              <Col align="end" className="px-0">
                <Button
                  color="transparent"
                  onClick={() => setShowForm(false)}
                  className="new-layout-btn main ml-lg-2 mb-2 mb-lg-0 btn btn-outline-secondary mr-2 font-size-14"
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  onClick={handleAdicionarProduto}
                  className="new-layout-btn main mb-2 mb-lg-0 font-size-14"
                >
                  {editIndex !== null ? 'Salvar' : 'Adicionar'}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default CartProducts;
