import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Badge,
  Card,
  CardBody,
  Col,
  CustomInput,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import IconButton from '~/components/IconButton';
import SaleStatusBadge from '~/components/SaleStatusBadge';
import history from '~/services/history';
import { CANCELLED } from '~/util/deliveryStatuses';
import {
  capitalize,
  formatPrice,
  formatWhatsappPhoneNumber,
} from '~/util/format';

export default function SaleInfoCard({
  sale,
  isSelected,
  onSelect,
  onDelete,
  showInfoSeller,
  params,
}) {
  const location = useLocation();
  const finalValue = useMemo(() => {
    return (
      Number(sale.amount) +
      Number(sale.freight_user) -
      (sale?.giftback?.amount && sale.giftback.amount > 0
        ? Number(sale.giftback.amount)
        : 0)
    );
  }, [sale]);

  const exceptionMoneyAndCart = useMemo(() => {
    if (
      (sale.payment_type === 201 ||
        sale.payment_type === 202 ||
        sale.payment_type === 203) &&
      sale?.status <= 2
    ) {
      return true;
    }
    return false;
  }, [sale]);

  const isEditable = useMemo(() => {
    if (sale?.status < 2 || exceptionMoneyAndCart) {
      return true;
    }
    return false;
  }, [sale, exceptionMoneyAndCart]);

  return (
    <Card
      style={{
        boxShadow: '0rem 0.275rem 0.3rem 0.1rem rgba(0, 0, 0, 0.035)',
        ...(sale.is_cross && {
          border: '1px solid #E8308A',
          borderLeft: '8px solid #E8308A',
        }),
      }}
      className="card new-layout list-card sale grow-on-hover overflow-hidden"
      onClick={() => {
        history.push({
          pathname: `/venda/${sale.id}`,
          state: { params },
        });
      }}
    >
      <CardBody className="card-body new-layout px-3 py-3">
        {/*  desktop version  */}

        <Row className="w-100 h-100 mx-0 d-none d-lg-flex align-items-center">
          <Col xs={1} align="center" className="h-100 d-none">
            <div
              className="h-100 d-flex flex-row justify-content-center align-items-center flex-wrap d-none"
              onClick={e => {
                e.stopPropagation();
              }}
              role="button"
              tabIndex={0}
              aria-label="checkbox-wrapper-div"
            >
              <CustomInput
                type="checkbox"
                id={`${sale.id}-checkbox`}
                className="text-primary"
                checked={isSelected}
                defaultValue={isSelected}
                onChange={() => onSelect(sale, isSelected)}
              />
            </div>
          </Col>
          <Col
            xs={showInfoSeller ? 1 : 2}
            className="d-flex flex-column align-items-start justify-content-center"
          >
            <Row className="w-100 mx-0">
              <Col
                className="px-0 col-xxl-6 new-layout-table-column-value h-100 text-break-break-all"
                xs={12}
              >
                #{sale.order_number}
              </Col>
              <Col className="px-0" xs={2}>
                {sale.is_imported && (
                  <>
                    <Badge
                      className="omnichat-badge"
                      id={`omnichat-badge-${sale.id}`}
                    >
                      OC
                    </Badge>
                    <UncontrolledTooltip target={`omnichat-badge-${sale.id}`}>
                      Esse é um carrinho do OmniChat
                    </UncontrolledTooltip>
                  </>
                )}
                {/* {sale.is_shareable && (
                  <>
                    <Badge
                      className="shareable-cart-badge"
                      id={`shareable-cart-badge-${sale.id}`}
                    >
                      CC
                    </Badge>
                    <UncontrolledTooltip
                      target={`shareable-cart-badge-${sale.id}`}
                    >
                      Esse é um carrinho compartilhável
                    </UncontrolledTooltip>
                  </>
                )}
                {sale.delivery_scheduled_to && (
                  <>
                    <Badge
                      className="scheduled-delivery-badge"
                      id={`scheduled-delivery-badge-${sale.id}`}
                    >
                      EA
                    </Badge>
                    <UncontrolledTooltip
                      target={`scheduled-delivery-badge-${sale.id}`}
                    >
                      Esse carrinho possui entrega agendada
                    </UncontrolledTooltip>
                  </>
                )} */}
              </Col>
            </Row>
          </Col>
          {showInfoSeller && (
            <Col xs={2} align="start">
              <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
                {sale?.handled?.broken_name ? (
                  <>
                    {sale.handled.broken_name.map((word, index) => (
                      <div
                        key={`${word}-${sale.id}`}
                        className={`new-layout-card-list-strong-value w-100 ${
                          index > 0 && 'text-truncate'
                        }`}
                      >
                        {word}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="new-layout-card-list-strong-value w-100">
                    -
                  </div>
                )}
              </div>
            </Col>
          )}
          <Col
            xs={1}
            align="start"
            className="d-flex flex-row align-items-center flex-wrap"
          >
            {sale.client.broken_name ? (
              <>
                {sale.client.broken_name.map((word, index) => (
                  <div
                    key={`${word}-${sale.id}`}
                    className={`new-layout-card-list-strong-value w-100 ${
                      index > 0 && 'text-truncate'
                    }`}
                  >
                    {word}
                  </div>
                ))}
              </>
            ) : (
              <div className="new-layout-card-list-strong-value w-100">-</div>
            )}
          </Col>
          <Col
            xs={2}
            align="start"
            className="d-flex flex-row align-items-center flex-wrap"
          >
            {sale.store.broken_fantasy_name.map((word, index) => (
              <div
                key={`${word}-${sale.id}`}
                className={`new-layout-table-column-value w-100 ${
                  index > 0 && 'text-truncate'
                }`}
              >
                {word}
              </div>
            ))}
          </Col>
          <Col xs={1} align="start">
            <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
              {finalValue ? (
                formatPrice(finalValue)
              ) : (
                <span className="ml-3 pl-3">-</span>
              )}
            </div>
          </Col>
          <Col xs={2} align="start" className="h-100 d-flex flex-row flex-wrap">
            <div className="new-layout-table-column-value h-100 d-flex flex-row flex-wrap">
              {sale.formatted_updated_at}
            </div>
          </Col>
          <Col
            xs={showInfoSeller ? 2 : 3}
            align="center"
            className="px-0 h-100 d-flex flex-row flex-wrap"
          >
            <div className="d-flex flex-row justify-content-center align-items-center h-100 w-100">
              <SaleStatusBadge cart={sale} />
            </div>
          </Col>
          <Col xs={1} align="center" className="h-100 px-2">
            <div
              className={`h-100 flex-row align-items-center justify-content-around ${
                location.pathname.includes('busca') ? 'd-none' : 'd-flex'
              }`}
            >
              <a
                href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                  sale?.client?.telephone
                )}`}
                target="_blank"
                rel="noreferrer"
                onClick={e => e.stopPropagation()}
              >
                <IconButton
                  icon="fab fa-whatsapp"
                  color="success"
                  id={`whatsapp-mobile-btn-${sale.id}`}
                  tip="Vender para Cliente"
                />
              </a>
              {isEditable && (
                <div>
                  <IconButton
                    icon="las la-pen"
                    color="primary"
                    id={`edit-btn-${sale.id}`}
                    tip="Editar"
                    forceTip
                    click={e => {
                      history.push(`/registrar/${sale.id}`);
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
              {sale.status_text === 'Aberto' && (
                <div>
                  <IconButton
                    icon="las la-trash"
                    color="danger"
                    id={`trash-btn-${sale.id}`}
                    tip="Excluir"
                    forceTip
                    click={e => {
                      onDelete(sale);
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>

        {/*  mobile version  */}

        <Row className="w-100 h-100 mx-0 d-lg-none">
          <Col className="px-0">
            <Row className="w-100 mx-0">
              <Col xs={10} align="start" className="px-0">
                <div className="new-layout-card-mobile font-weight-600">
                  {sale.client.name}
                </div>
              </Col>
              <Col xs={2} className="px-0" align="end">
                <div
                  className={`h-100 ${
                    location.pathname.includes('busca') ? 'd-none' : 'd-flex'
                  } flex-row ${
                    sale.status_text === 'Aberto'
                      ? 'justify-content-between'
                      : 'justify-content-end'
                  }`}
                >
                  {!isEditable && (
                    <a
                      href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                        sale?.client?.telephone
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={e => e.stopPropagation()}
                    >
                      <IconButton
                        icon="fab fa-whatsapp"
                        color="success"
                        id={`whatsapp-mobile-btn-${sale.id}`}
                        tip="Vender para Cliente"
                      />
                    </a>
                  )}
                  {isEditable && (
                    <div className={`${sale.status_text !== 'Aberto' && ''}`}>
                      <IconButton
                        icon="las la-pen"
                        color="primary"
                        id={`edit-mobile-btn-${sale.id}`}
                        tip="Editar"
                        click={e => {
                          history.push(`/registrar/${sale.id}`);
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  )}
                  {sale.status_text === 'Aberto' && (
                    <IconButton
                      icon="las la-trash"
                      color="danger"
                      id={`trash-mobile-btn-${sale.id}`}
                      tip="Excluir"
                      click={e => {
                        onDelete(sale);
                        e.stopPropagation();
                      }}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="w-100 mx-0 mb-2">
              <SaleStatusBadge cart={sale} />
              {sale.is_imported && (
                <Badge className="omnichat-badge ml-2">OC</Badge>
              )}
              {/* {sale.is_shareable && (
                <Badge className="shareable-cart-badge ml-2">CC</Badge>
              )}
              {sale.delivery_scheduled_to && (
                <Badge className="scheduled-delivery-badge ml-2">CC</Badge>
              )} */}
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label font-weight-500 d-inline">
                    Número do Pedido:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    #{sale.order_number}
                  </div>
                </div>
              </Col>
            </Row>
            {/* showInfoSeller && (
              <Row className="w-100 mx-0">
                <Col className="px-0">
                  <div className="d-flex flex-row align-items-baseline">
                    <div className="new-layout-card-mobile label d-inline">
                      Vendedor:
                    </div>
                    <div className="new-layout-card-mobile value d-inline text-truncate">
                      {sale?.seller?.name || 'Não informado'}
                    </div>
                  </div>
                </Col>
              </Row>
            ) */}
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label font-weight-500 d-inline">
                    Valor:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {finalValue ? formatPrice(finalValue) : <span>-</span>}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label font-weight-500 d-inline">
                    Loja:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {sale.store.fantasy_name}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label font-weight-500 d-inline">
                    Últ. Atualização:
                  </div>
                  <div className="new-layout-card-mobile value d-inline text-truncate">
                    {sale.formatted_updated_at}
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row className="w-100 mx-0">
              <Col className="px-0">
                <div className="d-flex flex-row align-items-baseline">
                  <div className="new-layout-card-mobile label d-inline">
                    Última Atualização:
                  </div>
                  <div className="new-layout-card-mobile value d-inline">
                    {sale.formatted_updated_at}
                  </div>
                </div>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </CardBody>
      {sale?.is_cross && sale?.cross_accepted ? (
        <Row
          className="w-100 mx-0 px-3 py-2 d-lg-none"
          style={{
            backgroundColor: '#E8308A',
          }}
        >
          <Col className="px-0">
            <div className="d-flex flex-row align-items-baseline">
              <div className="new-layout-card-mobile label font-weight-500 d-inline text-white">
                Pedido recebido:
              </div>
              <div className="new-layout-card-mobile value d-inline text-white">
                {sale.handled_by.name ? capitalize(sale.handled_by.name) : null}
              </div>
            </div>
          </Col>
        </Row>
      ) : null}

      {(sale?.status_text === 'Malinha entregue' ||
        sale?.status_text === 'Entregue') &&
      sale?.return_delivery &&
      sale.return_delivery?.status !== CANCELLED &&
      sale?.return_delivery?.worker_name ? (
        <Row
          className="w-100 mx-0 px-3 py-2 d-lg-none"
          style={{
            backgroundColor:
              sale?.return_delivery?.status !== 'CANCELED'
                ? '#967ada'
                : '#eca120',
          }}
        >
          <Col className="px-0">
            <div className="d-flex flex-row align-items-baseline">
              <div className="new-layout-card-mobile label font-weight-500 d-inline text-white">
                Entregador:
              </div>
              <div className="new-layout-card-mobile value d-inline text-white">
                {sale.return_delivery.worker_name
                  ? capitalize(sale.return_delivery.worker_name)
                  : null}
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      {sale.status_text === 'Encaminhado por estoquista' &&
        sale.status_text !== 'Malinha entregue' &&
        !sale.return_delivery &&
        sale.delivery?.status !== CANCELLED &&
        sale?.delivery?.worker_name && (
          <Row
            className="w-100 mx-0 px-3 py-2 d-lg-none"
            style={{
              backgroundColor:
                sale?.delivery?.status !== 'CANCELED' ? '#967ada' : '#eca120',
            }}
          >
            <Col className="px-0">
              <div className="d-flex flex-row align-items-baseline">
                <div className="new-layout-card-mobile label font-weight-500 d-inline text-white">
                  Entregador:
                </div>
                <div className="new-layout-card-mobile value d-inline text-white">
                  {sale.delivery.worker_name
                    ? capitalize(sale.delivery.worker_name)
                    : null}
                </div>
              </div>
            </Col>
          </Row>
        )}
    </Card>
  );
}
