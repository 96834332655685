import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast, Flip } from 'react-toastify';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import api from '~/services/api';
import history from '~/services/history';
import { CANCELLED, DELIVERED, SEARCHING } from '~/util/deliveryStatuses';
import { formatWhatsappPhoneNumber } from '~/util/format';
import { getRole } from '~/util/getRole';

const ToastSupportContent = ({ order_number }) => (
  <p>
    Erro ao chamar entregador, fale com o{' '}
    <a
      href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
        '5511935354434'
      )}&text=Erro ao chamar entregador, você pode me ajudar?%0A%0AID da Venda: %23${order_number}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      suporte Viddy
    </a>
    .
  </p>
);

export default function ConsultantManagementButtons({
  sale,
  delivery,
  separated,
  salePaymentReverse,
  toggleFinishCancelledOrder,
  cartIndex,
  cartsArrLength,
  handleFinishUnify,
  handleFinishAndNext,
  setShouldReloadCart,
  setShouldShowColectDeliveryForm,
  setShouldShowPaymentSuitcaseform,
  formRef,
}) {
  const roles = useSelector(({ user }) => user.profile.roles);

  const [reverseModal, setReverseModal] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [nextStatusButtonLoading, setNextStatusButtonLoading] =
    React.useState(false);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'cross-manager',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const location = useLocation();
  const toastID = useRef(null);

  const subscriberId = localStorage.getItem('@VIDDY:X-Server');
  const subscriberNomura = '6888af2c-b1c1-431d-a7e6-aea35d2f0286';
  // const subscriberStaging = "04c1ecc3-fe7a-4d26-880f-5cb1689c79fe";
  /* const handleFinishOrder = async () => {
    // if (!sale.nfce?.number) {
    //   toast.error(
    //     'O pedido não tem número de nota fiscal cadastrado, para marcar como entregue é necessário inserir este número.',
    //     { autoClose: 6000 }
    //   );
    //   formRef.current.setFieldError(
    //     'receipt_number',
    //     'Insira um número de nota fiscal'
    //   );
    //   return;
    // }
    try {
      await api.patch(`utils/finalize-for-test/${sale.id}`);

      if (sale.is_suitcase_delivery) {
        setShouldReloadCart(true);
        return;
      }

      history.push('/');
      toast.success('Venda finalizada!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  }; */

  const handleFinishOrderWhenNotDelivery = async () => {
    try {
      if (subscriberId === subscriberNomura) {
        const nfceNumber = formRef.current.getFieldValue('receipt_number');

        if (!nfceNumber) {
          toast.error('O número da nota fiscal é obrigatório!', {
            autoClose: 6000,
          });
          formRef.current.setFieldError(
            'receipt_number',
            'Insira um número de nota fiscal'
          );
          setButtonLoading(false);
          return;
        }
      }

      await api.patch(`${role}/carts/${sale.id}/finalize`);

      history.push('/');
      toast.success('Venda finalizada!');
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    }
  };

  const handleMarkAsReady = async () => {
    setButtonLoading(true);
    if (sale.is_for_delivery) {
      if (subscriberId === subscriberNomura) {
        const nfceNumber = formRef.current.getFieldValue('receipt_number');

        if (!nfceNumber) {
          toast.error('O número da nota fiscal é obrigatório!', {
            autoClose: 6000,
          });
          formRef.current.setFieldError(
            'receipt_number',
            'Insira um número de nota fiscal'
          );
          setButtonLoading(false);
          return;
        }
      }

      try {
        toastID.current = toast.info('Registrando chamada...', {
          autoClose: false,
        });
        await api.patch(`${role}/carts/${sale.id}/mark-as-ready`);

        toast.update(toastID.current, {
          render: 'Chamada registrada com sucesso! Redirecionando...',
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          transition: Flip,
        });
        history.push('/');
      } catch (err) {
        setButtonLoading(false);
        toast.update(toastID.current, {
          render: () => (
            <ToastSupportContent order_number={sale.order_number} />
          ),
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          transition: Flip,
          delay: 750,
        });
      }
    }
  };

  const nextStatus = async () => {
    setNextStatusButtonLoading(true);
    try {
      await api.patch(`/utils/update-delivery-for-test/${sale.id}`);

      setShouldReloadCart(true);
    } catch (err) {
      console.error(err);
      toast.error('Erro ao passar para o próximo status.');
    } finally {
      setNextStatusButtonLoading(false);
    }
  };

  const shouldShowNextStatusButton = useMemo(() => {
    if (
      !['teste', 'homologacao', 'treinamento', 'demo', 'localhost'].includes(
        window.location.host?.split('.')?.shift()?.split(':')?.shift()
      )
    )
      return false;

    if (!['BEE', 'MOTTU'].includes(sale.delivery_method)) return false;

    if (sale?.status === 0) return false;

    if (sale.is_suitcase_delivery) {
      if (
        !sale.return_delivery &&
        (sale.status_text === 'Aguardando envio' ||
          sale.status_text === 'Malinha entregue' ||
          sale.delivery?.status === CANCELLED)
      )
        return false;

      if (
        sale.return_delivery &&
        (sale.return_delivery?.status === CANCELLED ||
          sale.status_text === 'Coleta realizada' ||
          sale.status_text === 'Pedido finalizado')
      )
        return false;

      return true;
    }

    if (
      !sale.is_suitcase_delivery &&
      ((sale.delivery &&
        !sale.return_delivery &&
        sale.delivery?.status !== 'CANCELED' &&
        sale?.status > 2 &&
        sale?.status < 4) ||
        (sale.status_text === 'Entregue' &&
          sale.return_delivery &&
          sale.return_delivery?.status !== 'CANCELED'))
    ) {
      return true;
    }

    return false;
  }, [sale]);

  const callTheDelivery = useMemo(
    () => sale?.status === 2 && sale.is_for_delivery,
    [sale]
  );

  const hideButtonSaleCross = useMemo(
    () =>
      (sale?.status >= 2 && sale.is_cross && role === 'digital-consultant') ||
      (sale.is_cross &&
        sale.status_text === 'Aguardando aceite' &&
        role === 'cross-manager'),
    [sale, role]
  );

  const hideButtonCorreios = useMemo(
    () => sale?.status >= 2 && sale?.delivery_method === 'CORREIOS',
    [sale]
  );

  /* const isMarkAsReadyDisabled = useMemo(() => {
    if (
      sale.is_suitcase_delivery &&
      !sale?.return_delivery &&
      (delivery?.status === RETURNING_TO_STORE ||
        delivery?.status === ARRIVED_AT_DESTINATION ||
        delivery?.status === DELIVERED ||
        delivery?.status === CANCELLED)
    ) {
      return true;
    }

    if (
      sale.is_suitcase_delivery &&
      sale?.return_delivery &&
      (sale?.return_delivery?.status === RETURNING_TO_STORE ||
        sale?.return_delivery?.status === ON_DELIVERY ||
        sale?.return_delivery?.status === ARRIVED_AT_DESTINATION ||
        sale?.return_delivery?.status === DELIVERED ||
        sale?.return_delivery?.status === CANCELLED)
    ) {
      return true;
    }

    if (
      !sale.is_suitcase_delivery &&
      sale.is_for_delivery &&
      ['BEE', 'MOTTU', 'UBER', 'PROPRIA'].includes(sale.delivery_method) &&
      !(
        delivery?.status === RETURNING_TO_STORE ||
        delivery?.status === ON_DELIVERY ||
        delivery?.status === ARRIVED_AT_DESTINATION ||
        delivery?.status === DELIVERED ||
        delivery?.status === CANCELLED
      )
    ) {
      return true;
    }

    return false;
  }, [delivery, sale]); */

  const isMarkAsReadyAvaliable = useMemo(() => {
    if (!sale.is_for_delivery && (sale.status === 2 || sale.status === 3))
      return true;

    if (
      sale.is_for_delivery &&
      sale.delivery_method === 'CORREIOS' &&
      (sale.status === 2 || sale.status === 3)
    )
      return true;

    if (
      !['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
        sale.delivery_method
      )
    )
      return false;

    if (sale?.status === 0) return false;

    if (sale.is_suitcase_delivery) {
      if (
        !sale.return_delivery &&
        (sale.status_text === 'Aguardando envio' ||
          sale.status_text === 'Malinha entregue' ||
          sale.delivery?.status === SEARCHING ||
          sale.delivery?.status === DELIVERED)
      )
        return false;

      if (
        sale.return_delivery &&
        (sale?.status >= 8 ||
          sale.return_delivery?.status === SEARCHING ||
          sale.return_delivery?.status === DELIVERED)
      )
        return false;

      return true;
    }

    if (
      (sale.delivery &&
        sale.delivery?.status !== SEARCHING &&
        sale.delivery?.status !== DELIVERED &&
        !sale?.delivery?.finalization_reason &&
        sale.status_text !== 'Entregue' &&
        sale.status_text !== 'Pedido devolvido' &&
        sale.status_text !== 'Estornado' &&
        sale?.status >= 3) ||
      (sale?.return_delivery &&
        sale?.return_delivery?.status !== SEARCHING &&
        sale?.return_delivery?.status !== DELIVERED &&
        !sale?.return_delivery?.finalization_reason)
    ) {
      return true;
    }

    return false;
  }, [sale]);

  const handleFinish = async () => {
    if (
      ['BEE', 'MOTTU', 'UBER', 'CORREIOS', 'PROPRIA'].includes(
        sale.delivery_method
      )
    ) {
      if (delivery?.status !== CANCELLED && sale.is_suitcase_delivery)
        await nextStatus();

      toggleFinishCancelledOrder();
    }

    if (!sale.is_for_delivery || sale.delivery_method === 'CORREIOS') {
      handleFinishOrderWhenNotDelivery();
    }
  };

  const shouldShowReturnDeliveryButton = useMemo(
    () =>
      sale?.is_for_delivery &&
      sale?.status === 4 &&
      sale?.status_text === 'Entregue' &&
      !sale?.return_delivery &&
      ![201, 202, 203, 401].includes(sale?.payment_type),
    [sale]
  );

  const shouldShowReversePaymentButton = useMemo(
    () =>
      (sale?.status === 4 && !sale?.is_for_delivery) ||
      (sale?.status === 10 && sale?.status_text === 'Pedido devolvido'),
    [sale]
  );

  const shouldShowGeneratePaymentButton = useMemo(
    () => sale.is_suitcase_delivery && sale?.status_text === 'Coleta realizada',
    [sale]
  );

  const shouldShowColectDeliveryButton = useMemo(
    () =>
      sale.is_suitcase_delivery &&
      sale?.status_text === 'Malinha entregue' &&
      !sale?.return_delivery,
    [sale]
  );

  const shouldShowMarkAsReadyButton = useMemo(
    () => sale.is_suitcase_delivery && sale?.status_text === 'Aguardando envio',
    [sale]
  );

  const shouldShowSuitcaseDeliveryButtons = useMemo(
    () =>
      sale?.is_suitcase_delivery &&
      sale?.status >= 3 &&
      sale?.status < 9 &&
      ['Aguardando envio', 'Malinha entregue', 'Coleta realizada'].includes(
        sale.status_text
      ) &&
      (sale?.status !== 8 ||
        (sale?.status === 8 && (!Number(sale.amount) || !sale.shortened_url))),
    [sale]
  );

  if (
    ![
      shouldShowSuitcaseDeliveryButtons,
      shouldShowGeneratePaymentButton,
      shouldShowColectDeliveryButton,
      shouldShowMarkAsReadyButton,
      shouldShowNextStatusButton,
      shouldShowReturnDeliveryButton,
      shouldShowReversePaymentButton,
      isMarkAsReadyAvaliable,
      callTheDelivery,
    ].includes(true)
  )
    return null;

  return (
    <Row className="w-100 mx-0 mt-0 mb-4 d-flex justify-content-start">
      <Col
        className="px-0 d-flex flex-wrap justify-content-start"
        style={{ gap: '0.5rem' }}
        xs={12}
        xl={6}
      >
        {shouldShowNextStatusButton && !hideButtonSaleCross && (
          <Button
            className={`new-layout-btn main mx-0 ${
              sale.is_for_delivery ? 'long-label' : ''
            } font-size-14 button-sale-action`}
            color="primary"
            size="lg"
            onClick={nextStatus}
            disabled={nextStatusButtonLoading}
          >
            Próximo Status
          </Button>
        )}
        {location.pathname.includes('unificar-entregas') ? (
          <>
            {cartIndex + 1 === cartsArrLength ||
            cartIndex === cartsArrLength ? (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main long-label mx-0 font-size-14 button-sale-action"
                onClick={() => handleFinishUnify()}
                disabled={!separated}
              >
                Chamar entregador
              </Button>
            ) : (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main mx-0 long-label button-sale-action"
                onClick={() => handleFinishAndNext(cartIndex)}
                disabled={!separated}
              >
                Marcar como separado e ir para o próximo
              </Button>
            )}
          </>
        ) : (
          <>
            {callTheDelivery && !hideButtonSaleCross && hideButtonCorreios && (
              <Button
                className={`new-layout-btn main mx-0 ${
                  sale.is_for_delivery ? 'long-label' : ''
                } font-size-14 button-sale-action`}
                color="primary"
                size="lg"
                onClick={handleMarkAsReady}
                disabled={buttonLoading}
              >
                Chamar Entregador
              </Button>
            )}
            {isMarkAsReadyAvaliable && (
              <Button
                color="primary"
                className="new-layout-btn main mx-0 font-size-14 button-sale-action px-0"
                size="lg"
                onClick={handleFinish}
              >
                Marcar como entregue
              </Button>
            )}
          </>
        )}
        {shouldShowSuitcaseDeliveryButtons && (
          <>
            {shouldShowMarkAsReadyButton && (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main mx-0 font-size-14 button-sale-action px-0"
                onClick={handleMarkAsReady}
              >
                Chamar Entregador
              </Button>
            )}
            {shouldShowColectDeliveryButton && (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main mx-0 font-size-14 button-sale-action px-0"
                onClick={() => setShouldShowColectDeliveryForm(true)}
              >
                Solicitar Coleta
              </Button>
            )}
            {shouldShowGeneratePaymentButton && (
              <Button
                color="primary"
                size="lg"
                className="new-layout-btn main mx-0 font-size-14 button-sale-action px-0"
                onClick={() => setShouldShowPaymentSuitcaseform(true)}
              >
                Gerar Cobrança
              </Button>
            )}
          </>
        )}
        {shouldShowReturnDeliveryButton && (
          <Button
            color="primary"
            size="lg"
            className="new-layout-btn main mx-0 font-size-14 button-sale-action px-0"
            onClick={() => setShouldShowColectDeliveryForm(true)}
          >
            Devolução
          </Button>
        )}
        {shouldShowReversePaymentButton && (
          <Button
            color="primary"
            size="lg"
            className="new-layout-btn main mx-0 font-size-14 button-sale-action px-0"
            onClick={() => setReverseModal(true)}
          >
            Solicitar estorno
          </Button>
        )}
      </Col>
      <Modal isOpen={reverseModal}>
        <ModalHeader toggle={() => setReverseModal(state => !state)}>
          Solicitar Estorno
        </ModalHeader>
        <ModalBody>
          <Row className="w-100 mx-0 mb-3">
            <Label for="groupName">
              Essa ação irá realizar o estorno do valor para o cliente, deseja
              continuar?
            </Label>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className="new-layout-btn main float-left mb-2 mb-lg-1"
            // disabled={newGroupName.length < 3}
            onClick={() => setReverseModal(false)}
          >
            Cancelar
          </Button>
          <Button
            color="success"
            className="new-layout-btn main"
            // disabled={newGroupName.length < 3}
            onClick={() => {
              salePaymentReverse(true);
              setReverseModal(false);
            }}
          >
            Confirmar
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Row>
  );
}
