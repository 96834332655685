import { getRoles } from '~/util/getRoles';

const getDashboardProperties = roles => {
  const { isMaster, isAdmin, isStoreManager, isFinancier } = roles;

  const hide = !(isAdmin || isFinancier || isStoreManager || isMaster);

  let path = '/dashboard';
  let label = 'Dashboard';

  if (isMaster) {
    path = '/master';
  }
  if (isAdmin) {
    path = '/dashboard';
  }
  if (isFinancier) {
    path = '/financeiro';
  }
  if (isStoreManager) {
    path = '/sua-loja';
    label = 'Sua Loja';
  }

  const data = {
    hide,
    path,
    label,
  };

  return data;
};

export const generateSidebarItems = roles => {
  const ROLES = getRoles(roles);
  const DASHBOARD_PROPERTIES = getDashboardProperties(ROLES);

  const {
    isMaster,
    isAdmin,
    isSupport,
    isStoreManager,
    isSalesman,
    // isStockist,
    isConsultant,
    isCrossManager,
  } = ROLES;
  return [
    {
      id: 3,
      classes: !(
        isAdmin ||
        isSalesman ||
        isStoreManager ||
        isConsultant ||
        isCrossManager
      )
        ? 'd-none'
        : '',
      path: '',
      exactPath: '/',
      icon: 'las la-home',
      label: 'Início',
    },

    {
      id: 2,
      classes: DASHBOARD_PROPERTIES.hide ? 'd-none' : '',
      path: DASHBOARD_PROPERTIES.path,
      icon: 'las la-chart-pie',
      label: DASHBOARD_PROPERTIES.label,
    },

    {
      id: 1,
      classes: !isMaster ? 'd-none' : '',
      path: '/assinantes',
      icon: 'las la-signature',
      label: 'Assinantes',
    },

    // {
    //   id: 4,
    //   classes: !isStockist ? 'd-none' : '',
    //   path: '/estoque',
    //   icon: 'las la-dolly',
    //   label: 'Estoque',
    // },

    {
      id: 5,
      classes: !(isAdmin || isStoreManager) ? 'd-none' : '',
      path: '/usuarios',
      icon: 'las la-user-friends',
      label: 'Usuários',
    },

    // {
    //   id: 6,
    //   classes: !(isAdmin || isStoreManager) ? 'd-none' : '',
    //   path: '/produtos',
    //   icon: 'las la-shopping-basket',
    //   label: 'Produtos',
    // },

    {
      id: 7,
      classes: !isAdmin ? 'd-none' : '',
      path: '/lojas',
      icon: 'las la-store',
      label: 'Lojas',
    },

    {
      id: 8,
      classes: !isAdmin ? 'd-none' : '',
      path: '/clientes',
      icon: 'fas fa-users',
      label: 'Clientes',
    },

    {
      id: 9,
      classes: 'd-none',
      path: '/codigos-de-indicacao',
      icon: 'las la-receipt',
      label: 'Códigos',
    },
    {
      id: 10,
      classes: 'd-none',
      path: '/faq',
      icon: 'las la-question',
      label: 'FAQ',
    },

    {
      id: 12,
      classes: 'd-none',
      path: '/anuncios',
      icon: 'las la-scroll',
      label: 'Anúncios',
    },

    /* {
      id: 17,
      path: '#',
      classes: isAdmin || isStoreManager || isConsultant ? '' : 'd-none',
      icon: 'las la-book',
      label: 'Agenda',
      childrens: [ */
    /* {
      id: 171,
      classes: isAdmin || isStoreManager || isConsultant ? '' : 'd-none',
      path: '/cartao-de-visitas',
      icon: 'las la-address-card',
      label: 'Meu Cartão',
    }, */

    /* {
          id: 2,
          classes: isAdmin || isStoreManager || isConsultant ? '' : 'd-none',
          path: '/carteira-de-clientes',
          icon: 'las la-wallet',
          label: 'Carteira de Clientes',
        }, */

    {
      id: 173,
      classes: isConsultant ? '' : 'd-none',
      path: '/relatorio-de-clientes',
      icon: 'las la-file-invoice',
      label: 'Relatório',
    },
    /* ],
    }, */

    /* {
      id: 19,
      path: '#',
      classes: isAdmin ? '' : 'd-none',
      icon: 'custom-icon custom-icon-split-circle',
      label: 'Split',
      childrens: [
        */
    {
      id: 19,
      classes: isAdmin ? '' : 'd-none',
      path: '/recebedores-split',
      icon: 'custom-icon custom-icon-receiver',
      label: 'Recebedores',
    } /*
      ],
    }, */,

    {
      id: 13,
      classes: !isAdmin ? 'd-none' : '',
      path: '/push-notifications',
      icon: 'las la-comment-dots',
      label: 'Notificações',
    },

    {
      id: 14,
      classes: isAdmin ? 'd-lg-none' : 'd-none',
      path: '/configuracoes',
      icon: 'las la-cog',
      label: 'Configurações',
    },

    {
      id: 11,
      classes: 'd-lg-none',
      path: '/editar-dados',
      icon: 'las la-user-edit',
      label: 'Meu perfil',
    },

    {
      id: 16,
      classes: isMaster ? '' : 'd-none',
      path: '/suportes',
      icon: 'las la-user-friends',
      label: 'Suporte',
    },

    {
      id: 18,
      classes: !isMaster && !isSupport ? '' : 'd-none',
      path: '#',
      icon: 'fab fa-whatsapp',
      label: 'Suporte',
    },

    {
      id: 15,
      classes: '',
      path: '#',
      alt_path: '/login',
      icon: 'las la-sign-out-alt',
      label: 'Sair',
    },

    // {
    //   id: 17,
    //   classes: isMaster ? '' : 'd-none',
    //   path: '/logs',
    //   icon: 'las la-book',
    //   label: 'Logs',
    // },
  ];
};
